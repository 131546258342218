/*
   @page user profile
*/

//Import require libraries
import React, { useEffect, useRef, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import FormControl from "@mui/material/FormControl";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@mui/material/Pagination";
import CustomTable from "components/Table/Table";
import Helper from "./../../util/Helper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectMui from "@mui/material/Select";
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { display } from "@mui/system";
import SignaturePad from "react-signature-canvas";
import { border } from "@mui/system";
import { color } from "@mui/system";
import { width } from "@mui/system";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    width: "90%",
    height: "45px",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  hiddenContent: { display: "none" },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  // const signCanvas = useRef();
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState("");
  const [scriptUrl, setScriptUrl] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [processor, setProcessor] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [vtNameOnCardReq, setVtNameOnCardReq] = useState(false);
  const [vtNameOnCardShow, setVtNameOnCardShow] = useState(true);
  const [plNameOnCardReq, setPlNameOnCardReq] = useState(false);
  const [plNameOnCardShow, setPlNameOnCardShow] = useState(true);

  const [vtCvvReq, setVtCvvReq] = useState(false);
  const [vtCvvShow, setVtCvvShow] = useState(true);
  const [plCvvReq, setPlCvvReq] = useState(false);
  const [plCvvShow, setPlCvvShow] = useState(true);

  const [vtPhoneReq, setVtPhoneReq] = useState(false);
  const [vtPhoneShow, setVtPhoneShow] = useState(true);
  const [plPhoneReq, setPlPhoneReq] = useState(false);
  const [plPhoneShow, setPlPhoneShow] = useState(true);

  const [vtEmailReq, setVtEmailReq] = useState(false);
  const [vtEmailShow, setVtEmailShow] = useState(true);
  const [plEmailReq, setPlEmailReq] = useState(false);
  const [plEmailShow, setPlEmailShow] = useState(true);

  const [vtCountryReq, setVtCountryReq] = useState(false);
  const [vtCountryShow, setVtCountryShow] = useState(true);
  const [plCountryReq, setPlCountryReq] = useState(false);
  const [plCountryShow, setPlCountryShow] = useState(true);

  const [vtAddressReq, setVtAddressReq] = useState(false);
  const [vtAddressShow, setVtAddressShow] = useState(true);
  const [plAddressReq, setPlAddressReq] = useState(false);
  const [plAddressShow, setPlAddressShow] = useState(true);

  const [vtCityReq, setVtCityReq] = useState(false);
  const [vtCityShow, setVtCityShow] = useState(true);
  const [plCityReq, setPlCityReq] = useState(false);
  const [plCityShow, setPlCityShow] = useState(true);

  const [vtDescriptionReq, setVtDescriptionReq] = useState(false);
  const [vtDescriptionShow, setVtDescriptionShow] = useState(true);
  const [plDescriptionReq, setPlDescriptionReq] = useState(false);
  const [plDescriptionShow, setPlDescriptionShow] = useState(true);

  const [vtZipReq, setVtZipReq] = useState(false);
  const [vtZipShow, setVtZipShow] = useState(true);
  const [plZipReq, setPlZipReq] = useState(false);
  const [plZipShow, setPlZipShow] = useState(true);

  const [vtStateReq, setVtStateReq] = useState(false);
  const [vtStateShow, setVtStateShow] = useState(true);
  const [plStateReq, setPlStateReq] = useState(false);
  const [plStateShow, setPlStateShow] = useState(true);
  const [position, setPosition] = useState(null);
  useEffect(() => {
    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }
    const checkFieldsLoad = setInterval(setFieldChecking, 500);
    getPaymentDetail();

    if (navigator.geolocation) {
      const successCallback = (pos) => {
        setPosition({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });

      };

      const errorCallback = (err) => {
        setErrorMsg(err.message || 'An unknown error occurred.');
        setError(true); // Data fetching has completed, even if it failed
      };

      // Request the user's current position
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      setErrorMsg('Geolocation is not supported by this browser.');
      setError(true); // Data fetching has completed, even if it failed
    }
  }, []);

  const getPaymentDetail = () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    const data = {};
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_payment_form_detail", data, {
        headers: headers,
      })
      .then((response) => {
        setProcessor(response.data.processor);
        setMaxTicketData(response.data.maxTicketData);
        if (response.data.processor == "TSYS TransIT") {
          setSelectedCurrency("USD");
          setSelectedCountry(233);
          setStateList(response.data.stateData["233"]);
        }
        setCountryList(response.data.countryData);
        var data = JSON.parse(JSON.stringify(response.data.stateData));
        setAllStateData(data);
        setScriptUrl(response.data.scriptUrl);
        appendScriptFunction(response.data.scriptUrl);

        if (response.data.e_commerce_fields) {
          var eCommData = JSON.parse(response.data.e_commerce_fields);
          setVtNameOnCardReq((eCommData.vt_name_on_card_req == "1") ? true : false);
          setVtNameOnCardShow((eCommData.vt_name_on_card_show == "1") ? true : false);
          setPlNameOnCardReq((eCommData.pl_name_on_card_req == "1") ? true : false);
          setPlNameOnCardShow((eCommData.pl_name_on_card_show == "1") ? true : false);
          setVtCvvReq((eCommData.vt_cvv_req == "1") ? true : false);
          setVtCvvShow((eCommData.vt_cvv_show == "1") ? true : false);
          setPlCvvReq((eCommData.pl_cvv_req == "1") ? true : false);
          setPlCvvShow((eCommData.pl_cvv_show == "1") ? true : false);
          setVtPhoneReq((eCommData.vt_phone_req == "1") ? true : false);
          setVtPhoneShow((eCommData.vt_phone_show == "1") ? true : false);
          setPlPhoneReq((eCommData.pl_phone_req == "1") ? true : false);
          setPlPhoneShow((eCommData.pl_phone_show == "1") ? true : false);
          setVtEmailReq((eCommData.vt_email_req == "1") ? true : false);
          setVtEmailShow((eCommData.vt_email_show == "1") ? true : false);
          setPlEmailReq((eCommData.pl_email_req == "1") ? true : false);
          setPlEmailShow((eCommData.pl_email_show == "1") ? true : false);
          setVtCountryReq((eCommData.vt_country_req == "1") ? true : false);
          setVtCountryShow((eCommData.vt_country_show == "1") ? true : false);
          setPlCountryReq((eCommData.pl_country_req == "1") ? true : false);
          setPlCountryShow((eCommData.pl_country_show == "1") ? true : false);
          setVtAddressReq((eCommData.vt_address_req == "1") ? true : false);
          setVtAddressShow((eCommData.vt_address_show == "1") ? true : false);
          setPlAddressReq((eCommData.pl_address_req == "1") ? true : false);
          setPlAddressShow((eCommData.pl_address_show == "1") ? true : false);
          setVtCityReq((eCommData.vt_city_req == "1") ? true : false);
          setVtCityShow((eCommData.vt_city_show == "1") ? true : false);
          setPlCityReq((eCommData.pl_city_req == "1") ? true : false);
          setPlCityShow((eCommData.pl_city_show == "1") ? true : false);
          setVtDescriptionReq((eCommData.vt_description_req == "1") ? true : false);
          setVtDescriptionShow((eCommData.vt_description_show == "1") ? true : false);
          setPlDescriptionReq((eCommData.pl_description_req == "1") ? true : false);
          setPlDescriptionShow((eCommData.pl_description_show == "1") ? true : false);
          setVtZipReq((eCommData.vt_zip_req == "1") ? true : false);
          setVtZipShow((eCommData.vt_zip_show == "1") ? true : false);
          setPlZipReq((eCommData.pl_zip_req == "1") ? true : false);
          setPlZipShow((eCommData.pl_zip_show == "1") ? true : false);
          setVtStateReq((eCommData.vt_state_req == "1") ? true : false);
          setVtStateShow((eCommData.vt_state_show == "1") ? true : false);
          setPlStateReq((eCommData.pl_state_req == "1") ? true : false);
          setPlStateShow((eCommData.pl_state_show == "1") ? true : false);
        }


      });
  };
  const scriptLoadSuccessFull = () => {
    setIsScriptLoaded(true);
  };
  const appendScriptFunction = (url) => {
    console.log(url);

    const script = document.createElement("script");
    script.src = url;
    script.onload = scriptLoadSuccessFull;
    script.async = true;
    document.body.append(script);

    // var d = document.createElement('span');
    // d.classList.add("inputHint");
    // d.innerText = "appended span";
    // const divCardNum = document.getElementById("tsep-cardNumDiv");
    // divCardNum.innerHTML="";
    // divCardNum.append(d)

    //"https://stagegw.transnox.com/transit-tsep-web/jsView/88800018495903?1a2af0e99156d41e0e41cf8ee3924d8fafe96f2a5d7aa1905baee9e5a1f7b4e9d1d322159fa825f3833f9b4366e7c983f3927e450333f1dd757ab94ab319da4f8ee6c7b1";
  };

  /*
   * Const custom style
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#80ADFF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1769ff",
      },
    }),
  };
  const [tableData, setTableData] = useState([]);
  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [btnClick, setBtnClick] = useState(false);

  const [cardHolderName, setCardHolderName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [vtPhone, setVtPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryCallingPlusCode, setCountryCallingCode] = useState("");
  const [countryCodeForPaymentLink, setCountryCodeForPaymentLink] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [maxTicketData, setMaxTicketData] = useState([]);
  const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState(false);
  const [isPaymentFail, setIsPaymentFail] = useState(false);

  const [tabValue, setTabValue] = React.useState(0);
  const [receiptphone, setReceiptPhone] = useState("");
  const [receiptemail, setReceiptEmail] = useState("");

  const [resAmount, setResAmount] = React.useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [responseMsg, setResponseMsg] = React.useState("");
  const [responseErrorMsgHeader, setResponseErrorMsgHeader] =
    React.useState("Payment Failed");
  const [responseErrorMsgSubHeader, setResponseErrorMsgSubHeader] =
    React.useState("");
  const [cvvStatus, setCvvStatus] = React.useState("");
  const [avsStatus, setAvsStatus] = React.useState("");
  const [ecrRefNumber, setEcrRefNumber] = React.useState("");
  const [isScriptLoaded, setIsScriptLoaded] = React.useState(false);

  const [isPaymentVoid, setIsPaymentVoid] = React.useState(false);

  const [isTextPopupOpen, setIsTextPopupOpen] = React.useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = React.useState(false);
  const [isEmialInValid, setIsEmialInValid] = React.useState(false);
  const [emailRetryCount, setEmailRetryCount] = React.useState(1);
  const [paymentDescription, setPaymentDescription] = React.useState("");

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setTabValue(newValue);
    if (newValue == 0) {
      // appendScriptFunction(scriptUrl)
    }
    if (newValue == 1) {
      if (document.getElementById("tsep-cardHolderName")) {
        setCardHolderName(document.getElementById("tsep-cardHolderName").value);
      }

    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const validateAmountValue = (e) => {
    var val = e.target.value;
    var per = val.replace(".", "").replaceAll(",", "");
    per = per / 100;
    per = parseFloat(per).toFixed(2);
    if (!isNaN(per)) {
      var str = per.toString().split(".");
      if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, "$1 ");
      }
      setAmount(str.join("."));
    }
  };

  const verifyEmial = (checkEmail) => {
    if (checkEmail) {
      const data = {};
      const headers = {
        'Origin': 'https://magicpaypos.com'
      };
      axios
        .get(
          "https://api.emailable.com/v1/verify?email=" +
          checkEmail +
          "&api_key=live_b7749b04789e17258f04",
          data,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setEmailRetryCount(1);
          if (response.data.state == "deliverable") {
            setIsEmialInValid(false);
            document.getElementById("emailInput").style.background =
              "url('/paymnet_card_icon/right_email_icon.PNG') no-repeat 94% 50%";
            document.getElementById("emailInput").style.backgroundSize = "30px";
            document.getElementById("emailInputSecond").style.background =
              "url('/paymnet_card_icon/right_email_icon.PNG') no-repeat 94% 50%";
            document.getElementById("emailInputSecond").style.backgroundSize =
              "30px";
          } else {
            setIsEmialInValid(true);
            document.getElementById("emailInput").style.background =
              "url('/paymnet_card_icon/wrong_email_icon.PNG') no-repeat 94% 50%";
            document.getElementById("emailInput").style.backgroundSize = "30px";
            document.getElementById("emailInputSecond").style.background =
              "url('/paymnet_card_icon/wrong_email_icon.PNG') no-repeat 94% 50%";
            document.getElementById("emailInputSecond").style.backgroundSize =
              "30px";
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("---- Email check error ---");
          var cnt = emailRetryCount;
          cnt++;
          console.log(
            "GlobalCount: " + emailRetryCount + " --- LocalCount: " + cnt
          );
          setEmailRetryCount(cnt);
          if (cnt <= 3) {
            // verifyEmial(checkEmail);
          }
        });
    } else {
      document.getElementById("emailInput").style.background = "none";
      document.getElementById("emailInputSecond").style.background = "none";
    }
  };

  const handlePayment = (paymentType) => {

   
    if (amount == "") {
      setErrorMsg("Please enter amount");
      setError(true);
      setBtnClick(false);
      return;
    }else if(maxTicketData[selectedCurrency]){
      if(parseFloat(amount.replaceAll(",", "")) > parseFloat(maxTicketData[selectedCurrency]))
      {
        setErrorMsg("Amount exceeds allowed maximum ticket limit.");
        setError(true);
        setBtnClick(false);
        return;
      }
      
    }

    setBtnClick(true);

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    var url = "save_and_pay_open_order";

    let data = {};
    if (paymentType == "1") {
      data.name = cardHolderName;
      data.address = "";
      data.city = "";
      data.state = "";
      data.country = "";
      data.zipcode = "";
      data.phone = phone;
      data.phone_code = countryCode;
      data.country_calling_code = countryCallingPlusCode;
      data.currency = selectedCurrency;
      data.email = email;
      data.amount = amount.replaceAll(",", "");
      data.payment_description = paymentDescription;
      data.payment_type = paymentType;
      data.orderId = "";
      data.parent_id = localStorage.getItem("parent_id");
      data.location_id = localStorage.getItem("location_id");
    } else {
      if (window.eventData === undefined || window.eventData === "") {
        setErrorMsg("Please enter correct card detail.");
        setError(true);
        setBtnClick(false);
        return;
      } else if (
        window.eventData.tsepToken == null ||
        window.eventData.tsepToken == "null" ||
        window.eventData.tsepToken == ""
      ) {
        setErrorMsg("Please enter correct card detail.");
        setError(true);
        setBtnClick(false);
        return;
      }
      data = window.eventData;
      data.name = document.getElementById("tsep-cardHolderName").value;
      data.address = address;
      data.city = city;
      data.country = countryList[selectedCountry];
      data.state =
        Object.keys(stateList).length > 0
          ? stateList[selectedState]
          : selectedState;
      data.zipcode = zip;
      data.currency = selectedCurrency;
      data.phone = vtPhone;
      data.phone_code = countryCode;
      data.country_calling_code = countryCallingPlusCode;
      data.email = email;
      data.amount = amount.replaceAll(",", "");
      data.payment_description = paymentDescription;
      data.payment_type = paymentType;
      data.orderId = "";
      if(position)
      {
        data.latitude = position.latitude;
        data.longitude = position.longitude;
      }
      
      data.parent_id = localStorage.getItem("parent_id");
      data.location_id = localStorage.getItem("location_id");
    }

    axios
      .post(window.apiUrl + url, data, {
        headers: headers,
      })
      .then((response) => {
        setBtnClick(false);
        setPhone("");
        if (paymentType == "1") {
          if (response.data.send_email == "1") {
            setSuccess(true);
            setSuccessMsg("Email/SMS sent Successfully!");
            setPhone("");
            document.getElementById("emailInputSecond").style.background =
              "none";
          } else {
            setError(true);
            setErrorMsg("Email/SMS sent failed.");
            setPhone("");
          }
        } else {
          setCvvStatus(response.data.cvv_status);
          setAvsStatus(response.data.avs_status);
          if (
            response.data.SaleResponse.status == "PASS" &&
            response.data.is_voided == 0
          ) {
            setSuccess(true);
            setSuccessMsg("Payment Successfully!");
            setEcrRefNumber(response.data.ecr_ref_num);
            setResAmount(response.data.SaleResponse.transactionAmount);
            setResponseCode(response.data.SaleResponse.responseCode);
            setResponseMsg(response.data.SaleResponse.responseMessage);
            setIsPaymentSuccessfull(true);
            document.querySelectorAll("[data-emailable]").style.display =
              "none";
          } else {
            if (response.data.displayMessage !== undefined) {
              setResponseErrorMsgHeader(response.data.displayMessage);
            }
            if (
              response.data.displayMessage == "Payment Voided" ||
              response.data.displayMessage == "Payment Blocked & Voided"
            ) {
              setIsPaymentVoid(true);
              if (
                response.data.voidReasonMessage !== undefined &&
                response.data.voidReasonMessage !== ""
              ) {
                setResponseErrorMsgSubHeader(response.data.voidReasonMessage);
              }
            }

            setIsPaymentFail(true);
            setEcrRefNumber(response.data.ecr_ref_num);
            setResAmount(response.data.SaleResponse.transactionAmount);
            setResponseCode(response.data.SaleResponse.responseCode);
            setResponseMsg(response.data.SaleResponse.responseMessage);
            document.querySelectorAll("[data-emailable]").style.display =
              "none";
          }
        }

        setCardHolderName("");
        processor == "TSYS TransIT"
          ? setSelectedCurrency("USD")
          : setSelectedCurrency("");
        setCity("");
        setZip("");
        setSelectedState("");
        setPhone("");
        setEmail("");
        setAmount("");
        setPaymentDescription("");
      })
      .catch((error) => {
        setBtnClick(false);
        setError(true);
        setErrorMsg("Some Error Occurred.");
        return error;
      });
  };

  const paymentAmount = parseFloat(resAmount);
  const optionsForValidateAmount = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const formattedAmount = paymentAmount.toLocaleString(
    "en-US",
    optionsForValidateAmount
  );

  /*
@function Sent receipt in email
*/
  const sendEmailReceipt = () => {
    const data = {
      receipt_email_address: receiptemail,
      ecr_ref_no: ecrRefNumber,
      total_amount: resAmount,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "send_receipt_email", data, {
        headers: headers,
      })
      .then((response) => {
        setIsEmailPopupOpen(false);
        setReceiptEmail("");
        // setEcrRefNumber(0);
        // setResAmount(0);
        if (response.data.msg === "success") {
          //Check receipt was sent in email successfully
          setSuccess(true);
          setSuccessMsg("Receipt Sent Successfully!");
        } else {
          setError(true);
          setErrorMsg("Some Error Occur,Receipt Not Sent!!");
        }
      })
      .catch((error) => {
        return error;
      });
  };
   const validatePLFields = () =>
    {
      if(plNameOnCardReq == "1" && !cardHolderName)
      {
        setErrorMsg("Please enter name on card.");
        setError(true);
        setBtnClick(false);
        return;
      }
      else if(plPhoneReq == "1" && !phone)
      {
        setErrorMsg("Please enter phone number.");
        setError(true);
        setBtnClick(false);
        return;
      }
      else if(plEmailReq == "1" && !email)
      {
        setErrorMsg("Please enter email.");
        setError(true);
        setBtnClick(false);
        return;
      }
      else if (isEmialInValid && plEmailReq == "1" && email) {
        setErrorMsg("Please enter correct email");
        setError(true);
        setBtnClick(false);
        
        return;
      }
      else if(plDescriptionReq == "1" && !paymentDescription)
      {
        setErrorMsg("Please enter Description.");
        setError(true);
        setBtnClick(false);
        return;
      }
      else {
        handlePayment(1);        
      }
    }
  const validateReqFields = () => {

    if (vtNameOnCardReq == "1" && document.getElementById("tsep-cardHolderName") != null && !document.getElementById("tsep-cardHolderName").value) {
      setErrorMsg("Please enter name on card.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtCvvReq == "1" && document.getElementById("tsep-cvv2") != null && !document.getElementById("tsep-cvv2").value) {
      setErrorMsg("Please enter cvv.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtPhoneReq == "1" && !phone) {
      setErrorMsg("Please enter phone number.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtEmailReq == "1" && !email) {
      setErrorMsg("Please enter email.");
      setError(true);
      setBtnClick(false);
      return;
    }
    else if (isEmialInValid && vtEmailReq == "1" && email) {
      setErrorMsg("Please enter correct email");
      setError(true);
      setBtnClick(false);
      
      return;
    } 
    else if (vtCountryReq == "1" && !countryList[selectedCountry]) {
      setErrorMsg("Please select country.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtAddressReq == "1" && !address) {
      setErrorMsg("Please enter address.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtCityReq == "1" && !city) {
      setErrorMsg("Please enter city.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtStateReq == "1" && !selectedState) {
      setErrorMsg("Please enter state.");
      setError(true);
      setBtnClick(false);
      return;
    } else if (vtZipReq == "1" && document.getElementById("tsep-zipCode") != null && !document.getElementById("tsep-zipCode").value) {
      setErrorMsg("Please enter zipcode.");
      setError(true);
      setBtnClick(false);
      return;
    }
    else if (vtDescriptionReq == "1" && document.getElementById("vtDescription") != null && !document.getElementById("vtDescription").value) {
      setErrorMsg("Please enter Description.");
      setError(true);
      setBtnClick(false);
      return;
    }
    else {
      setTimeout(() => {
        handlePayment(0);
      }, 4000);
      setBtnClick(true);
    }

  }
  /*
     @function Sent receipt in sms
   */
  const sendSMSReceipt = () => {
    const data = {
      number: receiptphone,
      ecrRefNum: ecrRefNumber,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "sms", data, {
        headers: headers,
      })
      .then((response) => {
        setIsTextPopupOpen(false);
        setReceiptPhone("");
        // setEcrRefNumber(0);
        setSuccess(true);
        setSuccessMsg("SMS Send Successfully!");
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(error.response.data.msg);
        return error;
      });
  };

  if (!isScriptLoaded) {
    //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Virtual Terminal</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sucess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Virtual Terminal</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Box
                    sx={{ width: "100%" }}
                    style={{
                      display:
                        isPaymentFail || isPaymentSuccessfull
                          ? "none"
                          : "block",
                    }}
                  >
                    <Box
                      id="tabBox"
                      className="tabBox"
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                    >
                      <Tabs
                        forceRenderTabPanel={true}
                        id="terminalTabs"
                        className="terminalTabs"
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          id="tabChangeBtn"
                          label="Charge Now"
                          {...a11yProps(0)}
                        />
                        <Tab label="Payment Link" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    {/* <form method="POST" id="payment-form">
                      <div id="tsep-cardNumDiv" data-auto-formatting="Y" data-validate-cc="Y" data-detect-card-type="Y">
                        <input id="tsep-cardNum" name="tsep-cardNum" placeholder="Card Number" autocomplete="cc-number" maxlength="23" type="text" class="tsep-validation-error" />
                      </div>
                      <div id="tsep-datepickerDiv" data-validate-expiry-date="Y">
                        <input id="tsep-datepicker" name="tsep-datepicker" placeholder="MM/YYYY" autocomplete="cc-exp" maxlength="7" type="text" />
                      </div>
                      <div id="tsep-cvv2Div" data-validate-cvv2="Y">
                        <input id="tsep-cvv2" name="tsep-cvv2" placeholder="CVV" autocomplete="off" maxlength="4" type="text" />
                      </div>
                      <div id="tsep-cardHolderNameDiv" data-validate-name="Y">
                        <input id="tsep-cardHolderName" name="tsep-cardHolderName" placeholder="Name on Card" autocomplete="cc-name" maxlength="30" type="text" />
                      </div>
                      <div id="tsep-zipCodeDiv" data-validate-zipcode="Y">
                        <input id="tsep-zipCode" name="tsep-zipCode" placeholder="Zip Code" autocomplete="billing postal-code" maxlength="10" type="text" />
                      </div>
                    </form> */}
                    <CustomTabPanel
                      style={{ display: tabValue === 0 ? "block" : "none" }}
                    >
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <form method="POST" id="payment-form">
                            <GridContainer>
                              {(vtNameOnCardShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <div style={{ position: "relative" }}>
                                  <span
                                    class="floating-label"
                                    id="cardHolderLabel"
                                  >
                                    Name on Card
                                  </span>
                                  <div
                                    id="tsep-cardHolderNameDiv"
                                    data-validate-name="Y"
                                  >
                                  </div>
                                </div>
                              </GridItem> : ""}


                              <GridItem
                                xs={6}
                                sm={6}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <TextField
                                    id="amount"
                                    variant="outlined"
                                    label="Amount"
                                    type="text"
                                    value={amount}
                                    onChange={(e) => {
                                      validateAmountValue(e);
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {selectedCurrency === "PEN"
                                            ? "S/."
                                            : "$"}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem
                                xs={6}
                                sm={6}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <InputLabel id="demo-simple-select-helper-label">
                                    Currency
                                  </InputLabel>
                                  <SelectMui
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={selectedCurrency}
                                    label="Currency"
                                    disabled={
                                      processor == "TSYS TransIT" ? true : false
                                    }
                                    onChange={(e) => {
                                      setSelectedCurrency(e.target.value);
                                    }} //
                                  >
                                    <MenuItem value={"USD"}>USD</MenuItem>
                                    <MenuItem value={"MXN"}>MXN</MenuItem>
                                    <MenuItem value={"CLP"}>CLP</MenuItem>
                                    <MenuItem value={"COP"}>COP</MenuItem>
                                    <MenuItem value={"PEN"}>PEN</MenuItem>
                                    <MenuItem value={"UF"}>UF</MenuItem>
                                  </SelectMui>
                                </FormControl>
                              </GridItem>

                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <div style={{ position: "relative" }}>
                                  <span
                                    class="floating-label"
                                    id="cardNumberLabel"
                                  >
                                    Card Number
                                  </span>
                                  <div
                                    id="tsep-cardNumDiv"
                                    data-auto-formatting="Y"
                                    data-validate-cc="Y"
                                    data-detect-card-type="Y"
                                  >

                                  </div>
                                </div>
                              </GridItem>
                              <GridItem
                                xs={6}
                                sm={6}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <div style={{ position: "relative" }}>
                                  <span
                                    class="floating-label"
                                    id="expiryDateLabel"
                                  >
                                    Expiry Date
                                  </span>
                                  <div
                                    id="tsep-datepickerDiv"
                                    data-validate-expiry-date="Y"
                                  ></div>
                                </div>
                              </GridItem>
                              {(vtCvvShow) ? <GridItem
                                xs={6}
                                sm={6}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <div style={{ position: "relative" }}>
                                  <span class="floating-label" id="cvvLabel">
                                    CVV
                                  </span>
                                  <div
                                    id="tsep-cvv2Div"
                                    data-validate-cvv2="Y"
                                  ></div>
                                </div>
                              </GridItem> : ""}

                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                {(vtPhoneShow) ? <div style={{ position: "relative" }}>
                                  <FormControl
                                    id="materialPhoneField"
                                    style={{ width: "100%", marginTop: "8px" }}
                                  >

                                    <span class="floating-label" id="phoneNoLabel">
                                      Phone Number
                                    </span>
                                    <MuiPhoneNumber defaultCountry={'us'}
                                      id="muiInputPhoneNo"
                                      disableAreaCodes="true"
                                      value={phone}
                                      onChange={(value) => {
                                        if (value) {
                                          const phoneNumberObj = parsePhoneNumberFromString(value);
                                          // console.log(phoneNumberObj); 
                                          if (phoneNumberObj) {
                                            if (phoneNumberObj.country)
                                              setCountryCode(phoneNumberObj.country);
                                            setCountryCallingCode(phoneNumberObj.countryCallingCode);
                                            setPhone(phoneNumberObj.number);
                                            setVtPhone(phoneNumberObj.nationalNumber);
                                          }
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </div> : ""}

                              </GridItem>
                              {(vtEmailShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <TextField
                                    error={isEmialInValid}
                                    sucess={true}
                                    // style={{background:(isEmialInValid ? "url('/paymnet_card_icon/right_email_icon.PNG') no-repeat 93% 50%":"url('/paymnet_card_icon/wrong_email_icon.PNG') no-repeat 93% 50%"),backgroundSize:"30px"}}
                                    id="emailInput"
                                    variant="outlined"
                                    label="Email Address"
                                    value={email}
                                    inputProps={{
                                      autocomplete: "new-password",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    onBlur={(e) => {
                                      verifyEmial(e.target.value);
                                    }}
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem> : ""}

                              {(vtCountryShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <InputLabel id="demo-simple-select-helper-label">
                                    Country
                                  </InputLabel>
                                  <SelectMui
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={selectedCountry}
                                    label="Country"
                                    onChange={(e) => {
                                      setSelectedState("");
                                      setSelectedCountry(e.target.value);
                                      allStateData[e.target.value] === undefined
                                        ? setStateList([])
                                        : setStateList(
                                          allStateData[e.target.value]
                                        );
                                    }} //
                                  >
                                    {Object.entries(countryList).map(
                                      ([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                          {value}
                                        </MenuItem>
                                      )
                                    )}
                                  </SelectMui>
                                </FormControl>
                              </GridItem> : ""}

                              {(vtAddressShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Address"
                                    value={address}
                                    onChange={(e) => {
                                      setAddress(e.target.value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem> : ""}

                              {(vtCityShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="City"
                                    value={city}
                                    onChange={(e) => {
                                      setCity(e.target.value);
                                    }}
                                    onBlur={() => {
                                      setSelectOpen(true);
                                      setIsFocus(false);
                                    }}
                                  />
                                </FormControl>
                              </GridItem> : ""}

                              {(vtStateShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                id="gridItemLowPadding"
                              >
                                {/* <div style={{ height: "10px",display: (countryList[selectedCountry] == "United States" || countryList[selectedCountry] == "Mexico" || countryList[selectedCountry] == "Canada" || countryList[selectedCountry] == "Israel") ? "flex" : "none" }}>&nbsp;</div> */}
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginTop: "18px",
                                    display:
                                      countryList[selectedCountry] !=
                                        undefined &&
                                        (countryList[selectedCountry] ==
                                          "United States" ||
                                          countryList[selectedCountry] ==
                                          "Mexico" ||
                                          countryList[selectedCountry] ==
                                          "Canada")
                                        ? "flex"
                                        : "none",
                                  }}
                                >
                                  <InputLabel id="demo-simple-select-helper-label">
                                    {countryList[selectedCountry] == "Canada"
                                      ? "Province"
                                      : "State"}
                                  </InputLabel>
                                  <SelectMui
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    autoFocus={isFocus}
                                    value={selectedState}
                                    label={
                                      countryList[selectedCountry] == "Canada"
                                        ? "Province"
                                        : "State"
                                    }
                                    open={selectOpen}
                                    onClose={() => setSelectOpen(false)}
                                    onOpen={() => {
                                      if (!open) {
                                        setSelectOpen(true);
                                      } else {
                                        setSelectOpen(false);
                                      }
                                    }}
                                    onClick={() => {
                                      if (selectOpen) {
                                        setSelectOpen(false);
                                      } else {
                                        setSelectOpen(true);
                                      }
                                    }}
                                    onChange={(e) => {
                                      setSelectedState(e.target.value);
                                      // e.target.focus();
                                      setIsFocus(true);
                                      console.log(isFocus);
                                      e.stopPropagation();
                                    }} //
                                  >
                                    {Object.entries(stateList).map(
                                      ([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                          {value}
                                        </MenuItem>
                                      )
                                    )}
                                  </SelectMui>
                                </FormControl>
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginTop: "18px",
                                    display:
                                      countryList[selectedCountry] ==
                                        "United States" ||
                                        countryList[selectedCountry] ==
                                        "Mexico" ||
                                        countryList[selectedCountry] ==
                                        "Canada" ||
                                        countryList[selectedCountry] == "Israel"
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="State"
                                    value={selectedState}
                                    onChange={(e) => {
                                      setSelectedState(e.target.value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem> : ""}

                              {(vtZipShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <div style={{ position: "relative" }}>
                                  <span
                                    class="floating-label"
                                    id="zipCodeLabel"
                                  >
                                    Zip Code
                                  </span>
                                  <div
                                    id="tsep-zipCodeDiv"
                                    data-validate-zipcode="Y"
                                  ></div>
                                </div>
                              </GridItem> : ""}

                              {(vtDescriptionShow) ? <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                id="paymentVtDescriptionGrid"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <TextField
                                    id="vtDescription"
                                    variant="outlined"
                                    label="Description"
                                    inputProps={{
                                      autocomplete: "new-password",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    value={paymentDescription}
                                    onChange={(e) => {
                                      setPaymentDescription(e.target.value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem> : ""}


                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                id="gridItemLowPadding"
                              >
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                {btnClick ? (
                                  <CircularProgress id="loginProgress"></CircularProgress>
                                ) : (
                                  <Button
                                    id="greenBtn"
                                    style={{ width: "25%" }}
                                    onClick={() => {
                                      validateReqFields();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                  >
                                    Charge
                                  </Button>
                                )}
                              </GridItem>
                            </GridContainer>
                         
                          </form>
                        </GridItem>
                      </GridContainer>
                    </CustomTabPanel>
                    <CustomTabPanel
                      style={{ display: tabValue === 1 ? "block" : "none" }}
                    >
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          id="gridItemLowPadding"
                        >
                          <GridContainer>
                            {(plNameOnCardShow) ? <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              id="gridItemLowPadding"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <TextField
                                  id="tsep-cardHolderNameForWeb"
                                  variant="outlined"
                                  label="Name on Card"
                                  value={cardHolderName}
                                  onChange={(e) => {
                                    setCardHolderName(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </GridItem> : ""}

                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              id="gridItemLowPadding"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <TextField
                                  id="amount2"
                                  variant="outlined"
                                  label="Amount"
                                  value={amount}
                                  onChange={(e) => {
                                    validateAmountValue(e);
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {selectedCurrency === "PEN"
                                          ? "S/."
                                          : "$"}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              id="gridItemLowPadding"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <InputLabel id="demo-simple-select-helper-label">
                                  Currency
                                </InputLabel>
                                <SelectMui
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={selectedCurrency}
                                  disabled={
                                    processor == "TSYS TransIT" ? true : false
                                  }
                                  label="Currency"
                                  onChange={(e) => {
                                    setSelectedCurrency(e.target.value);
                                  }} //
                                >
                                  <MenuItem value={"USD"}>USD</MenuItem>
                                  <MenuItem value={"MXN"}>MXN</MenuItem>
                                  <MenuItem value={"CLP"}>CLP</MenuItem>
                                  <MenuItem value={"COP"}>COP</MenuItem>
                                  <MenuItem value={"PEN"}>PEN</MenuItem>
                                  <MenuItem value={"UF"}>UF</MenuItem>
                                </SelectMui>
                              </FormControl>
                            </GridItem>
                            {(plPhoneShow) ? <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              id="gridItemLowPadding"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                id="materialPhoneField"
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <span class="floating-label" id="phoneNoLabel2">
                                  Phone Number
                                </span>
                                <MuiPhoneNumber defaultCountry={'us'}
                                  id="muiInputPhoneNo2"
                                  disableAreaCodes="true"
                                  value={phone}
                                  onChange={(value) => {
                                    if (value) {
                                      const phoneNumberObj = parsePhoneNumberFromString(value);
                                      if (phoneNumberObj) {
                                        if (phoneNumberObj.country)
                                        setCountryCode(phoneNumberObj.country);
                                        setCountryCallingCode(phoneNumberObj.countryCallingCode);
                                        setPhone(phoneNumberObj.number);
                                      }
                                    }
                                  }
                                  }
                                />
                              </FormControl>
                            </GridItem> : ""}
                            {(plEmailShow) ? <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              id="gridItemLowPadding"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <TextField
                                  error={isEmialInValid}
                                  id="emailInputSecond"
                                  variant="outlined"
                                  label="Email Address"
                                  inputProps={{
                                    autocomplete: "new-password",
                                    form: {
                                      autocomplete: "off",
                                    },
                                  }}
                                  onBlur={(e) => {
                                    verifyEmial(e.target.value);
                                  }}
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </GridItem> : ""}

                            {(plDescriptionShow) ? <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              id="paymentDescriptionGrid"
                            >
                              <div style={{ height: "10px" }}>&nbsp;</div>
                              <FormControl
                                style={{ width: "100%", marginTop: "8px" }}
                              >
                                <TextField
                                  id="description"
                                  variant="outlined"
                                  label="Description"
                                  inputProps={{
                                    autocomplete: "new-password",
                                    form: {
                                      autocomplete: "off",
                                    },
                                  }}
                                  value={paymentDescription}
                                  onChange={(e) => {
                                    setPaymentDescription(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </GridItem> : ""}


                            <GridItem
                              xs={12}
                              sm={12}
                              md={4}
                              id="gridItemLowPadding"
                            >
                              <center>
                                <div style={{ height: "10px" }}>&nbsp;</div>
                                <FormControl
                                  style={{ width: "100%", marginTop: "3px" }}
                                  className={classes.popupHalfWidth}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                >
                                  {btnClick ? (
                                    <CircularProgress
                                      id="loginProgress"
                                      color="inherit"
                                    ></CircularProgress>
                                  ) : (
                                    <Button
                                      id="greenBtn"
                                      onClick={() => {
                                        validatePLFields();                                       
                                      }}
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        backgroundColor: "#ffa726",
                                        color: "#FFFFFF",
                                        width: "100%",
                                        height: "55px",
                                      }}
                                      className={classes.button}
                                    >
                                      Send Payment Link
                                    </Button>
                                  )}
                                </FormControl>
                              </center>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </CustomTabPanel>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {isPaymentSuccessfull ? (
                    <center>
                      <div id="paymentSuccessSection">
                        <div
                          class="paymentSuccessSectionCloseBtn"
                          onClick={(e) => {
                            location.reload();
                            setIsPaymentSuccessfull(false);
                          }}
                        >
                          <span>X</span>
                        </div>
                        <div
                          style={{ marginBottom: "20px", marginTop: "20px" }}
                        >
                          <img
                            width="100"
                            height="100"
                            src="/payment_success2.png"
                          />
                          <h5
                            style={{ marginBottom: "10px", color: "#2bb673" }}
                          >
                            Payment Approved
                          </h5>
                        </div>
                        <hr></hr>
                        <div class="card-body">
                          <h3>
                            Total Amount{" "}
                            <span style={{ color: "#2bb673" }}>
                              ${formattedAmount}
                            </span>
                          </h3>
                          <div>ResponseCode: {responseCode}</div>
                          <div>ResponseMessage: {responseMsg}</div>
                          <div>CVV Status: {cvvStatus}</div>
                          <div>AVS Status: {avsStatus}</div>
                          {/* <div >AuthCode:{responseAuthCode}</div>
                        <div >TransactionID:{responseTransId}</div> */}

                          <div
                            style={{ marginBottom: "0px", marginTop: "30px" }}
                          >
                            <a
                              href={
                                window.domainUrl + "receipt/" + ecrRefNumber
                              }
                              target="_blank"
                            >
                              View Receipt
                            </a>
                          </div>
                          <div
                            style={{
                              justifyContent: "space-between",
                              marginTop: "20px",
                            }}
                          >
                            <Button
                              id="btnEmailSuccessScreen"
                              onClick={() => {
                                setIsTextPopupOpen(true);
                              }}
                            >
                              Text Receipt
                            </Button>
                            <Button
                              id="btnEmailSuccessScreen"
                              onClick={() => {
                                setIsEmailPopupOpen(true);
                              }}
                            >
                              Email Receipt
                            </Button>
                          </div>
                        </div>
                      </div>
                    </center>
                  ) : (
                    ""
                  )}

                  {isPaymentFail ? (
                    <center>
                      <div id="paymentFailSection">
                        <div
                          class="paymentSuccessSectionCloseBtn"
                          onClick={(e) => {
                            location.reload();
                            setIsPaymentFail(false);
                          }}
                        >
                          <span>X</span>
                        </div>
                        <div
                          style={{ marginBottom: "20px", marginTop: "20px" }}
                        >
                          <img
                            width="100"
                            height="100"
                            src="/payment_failed.png"
                          />
                          <h5 style={{ marginBottom: "10px", color: "red" }}>
                            {responseErrorMsgHeader}
                          </h5>
                          {responseErrorMsgSubHeader ? (
                            <div
                              style={{
                                marginBottom: "10px",
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {responseErrorMsgSubHeader}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <hr></hr>
                        <div class="card-body">
                          <h3>
                            Total Amount{" "}
                            <span style={{ color: "red" }}>
                              ${formattedAmount}
                            </span>
                          </h3>
                          <div>ResponseCode: {responseCode}</div>
                          <div>ResponseMessage: {responseMsg}</div>
                          <div>CVV Status: {cvvStatus}</div>
                          <div>AVS Status: {avsStatus}</div>
                          {isPaymentVoid ? (
                            <div>
                              <div
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "30px",
                                }}
                              >
                                <a
                                  href={
                                    window.domainUrl +
                                    "receipt/" +
                                    ecrRefNumber
                                  }
                                  target="_blank"
                                >
                                  View Receipt
                                </a>
                              </div>
                              <div
                                style={{
                                  justifyContent: "space-around",
                                  marginTop: "20px",
                                  display: "flex",
                                }}
                              >
                                <Button
                                  id="btnEmailSuccessScreen"
                                  onClick={() => {
                                    setIsTextPopupOpen(true);
                                  }}
                                >
                                  Text Receipt
                                </Button>
                                <Button
                                  id="btnEmailSuccessScreen"
                                  onClick={() => {
                                    setIsEmailPopupOpen(true);
                                  }}
                                >
                                  Email Receipt
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <Button
                              id="btnCloseFailedScreen"
                              onClick={() => {
                                location.reload();
                                setIsPaymentFail(false);
                              }}
                            >
                              Close
                            </Button>
                          )}
                        </div>
                      </div>
                    </center>
                  ) : (
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}></GridItem>
              </GridContainer>

              {/* send text Popup start */}
              <Dialog
                open={isTextPopupOpen}
                keepMounted
                onClose={() => { }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  Text Receipt
                </DialogTitle>
                <DialogContent>
                  <FormControl style={{ width: "100%", marginTop: "8px" }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Phone Number"
                      value={receiptphone}
                      onChange={(e) => {
                        setReceiptPhone(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      setIsTextPopupOpen(false);
                    }}
                    color="primary"
                    id="btnGreyCancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      sendSMSReceipt();
                    }}
                    color="secondery"
                    id="btnGreenEdit"
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
              {/* send text Popup end  */}

              {/* send email Popup start */}
              <Dialog
                open={isEmailPopupOpen}
                keepMounted
                onClose={() => { }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  Email Receipt
                </DialogTitle>
                <DialogContent>
                  <FormControl style={{ width: "100%", marginTop: "8px" }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Email Address"
                      value={receiptemail}
                      onChange={(e) => {
                        setReceiptEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      setIsEmailPopupOpen(false);
                    }}
                    color="primary"
                    id="btnGreyCancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      sendEmailReceipt();
                    }}
                    color="secondery"
                    id="btnGreenEdit"
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
              {/* send email Popup end  */}
            </CardBody>

            <CardFooter></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
